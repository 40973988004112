<template>
	<div class="news">
		<header class="news_header">
			<div class="news_header_top">
				<the-nav></the-nav>
			</div>
			<div class="news_header_bottom w1200">
				<h1>新闻动态</h1>
				<h6>NEWS</h6>
			</div>
		</header>
		<div class="news_tags">
			<div class="news_tags_con w1200">
				<div
					class="news_tags_item"
					v-for="(item, index) in tagList"
					:key="'tag' + index"
					:class="[item.id == currentTagId && 'tag_active']"
					@click="changeTag(item)"
				>
					{{ item.tagName }}
				</div>
			</div>
		</div>
		<div class="news_list w1200">
			<template v-if="showNewsList && showNewsList.length">
				<div
					class="news_list_item"
					v-for="(item, index) in showNewsList"
					:key="'news' + index"
					@click="
						$router.push({
							name: 'newsDetail',
							query: { id: item.id }
						})
					"
				>
					<div class="left">
						<img :src="item.coverImg" alt="" srcset="" />
					</div>
					<div class="right">
						<div class="title">{{ item.title }}</div>
						<div class="time">{{ item.date }}</div>
						<div class="tags">
							<div
								class="tags_item"
								v-for="(tag, ti) in item.tags"
								:key="'tag' + ti"
							>
								{{ tag.tagName }}
							</div>
						</div>
					</div>
				</div>
				<div
					class="load_more"
					@click="loadMore"
					v-show="pagination.total > showNewsList.length"
				>
					加载更多
				</div>
			</template>
			<span v-else>{{ loading ? "加载中..." : "暂无新闻" }}</span>
		</div>
		<the-footer></the-footer>
	</div>
</template>

<script>
	export default {
		name: "news",
		data() {
			return {
				currentTagId: 0,
				tagList: [{ id: 0, tagName: "全部" }],
				newsList: [],
				pagination: {
					current: 1,
					pageSize: 4,
					total: 0
				},
				loading: false
			};
		},
		computed: {
			showNewsList() {
				let result = [];
				if (this.currentTagId == 0) {
					result = this.newsList;
				} else {
					this.newsList.map(item => {
						if (item.tags.some(tag => tag.id == this.currentTagId))
							result.push(item);
					});
				}
				let start =
					(this.pagination.current - 1) * this.pagination.pageSize;
				return result.slice(0, start + 4);
			}
		},
		mounted() {
			this.getTagList();
			this.getNewsList();
		},
		methods: {
			getTagList() {
				this.$getJSON("/api/tag/list", { method: "GET" }).then(res => {
					let list = [{ id: 0, tagName: "全部" }].concat(
						res.data || []
					);
					this.tagList = list;
				});
			},
			changeTag(item) {
				let temp = [];
				this.pagination.current = 1;
				if (item.id == 0) {
					temp = this.newsList;
				} else {
					this.newsList.filter(filterItem => {
						if (filterItem.tags.some(tag => tag.id == item.id)) {
							temp.push(filterItem);
						}
					});
				}
				this.pagination.total = temp.length;
				this.currentTagId = item.id;
				temp = null;
			},
			getNewsList() {
				this.loading = true;
				this.$getJSON("/api/news/list", { method: "GET" })
					.then(res => {
						this.newsList = res.data;
						localStorage.setItem(
							"newsList",
							JSON.stringify(this.newsList)
						);
						this.pagination.total = this.newsList.length;
					})
					.finally(() => (this.loading = false));
			},
			loadMore() {
				this.pagination.current += 1;
			}
		}
	};
</script>

<style lang="scss">
	.news {
		&_header {
			height: 304px;
			background-image: url("../../assets/imgs/img-news-top-bg.png");
			background-position: center;
			background-size: 3840px 100%;
			&_top {
				height: 80px;
				background: rgba(255, 255, 255, 0);
				box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
			}
			&_bottom {
				color: #fff;
				text-align: left;
				h1 {
					margin-top: 64px;
					margin-bottom: 8px;
					font-size: 30px;
					font-family: PingFangSC, PingFangSC-Semibold;
					font-weight: 600;
				}
				h6 {
					font-size: 14px;
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					color: rgba(255, 255, 255, 0.45);
				}
			}
		}
		&_tags {
			min-height: 64px;
			background-color: #f4f5f7;
			&_con {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				padding: 16px 0 8px 0;
			}
			&_item {
				padding: 5px 16px;
				margin-right: 8px;
				margin-bottom: 8px;
				background: #ffffff;
				border: 1px solid #d9d9d9;
				border-radius: 2px;
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: rgba(0, 0, 0, 0.65);
				cursor: pointer;
				&:hover {
					color: #00c699;
					border-color: #00c699;
				}
			}
			.tag_active {
				color: #00c699;
				border-color: #00c699;
			}
		}
		&_list {
			padding: 48px 100px;
			&_item {
				display: flex;
				justify-content: flex-start;
				// border: 1px solid red;
				background-color: #f9f9f9;
				margin-bottom: 25px;
				cursor: pointer;
				&:hover {
					.right {
						.title {
							color: #00c699;
							text-decoration: underline;
						}
					}
				}
				.left {
					width: 395px;
					height: 224px;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.right {
					flex: 1;
					padding: 24px;
					.title {
						font-size: 24px;
						font-family: PingFangSC, PingFangSC-Medium;
						font-weight: 500;
						text-align: left;
						color: rgba(0, 0, 0, 0.85);
						word-break: break-all;
					}
					.time {
						margin: 8px 0 16px 0;
						font-size: 14px;
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						text-align: left;
						color: rgba(0, 0, 0, 0.45);
					}
					.tags {
						display: flex;
						flex-wrap: wrap;
						&_item {
							padding: 1px 8px;
							margin-right: 4px;
							margin-bottom: 8px;
							border: 1px solid #d9d9d9;
							border-radius: 2px;
							font-size: 12px;
							font-family: PingFangSC, PingFangSC-Regular;
							font-weight: 400;
							text-align: left;
							color: rgba(0, 0, 0, 0.65);
						}
					}
				}
			}
			.load_more {
				width: 128px;
				height: 46px;
				line-height: 44px;
				border: 1px solid rgba(0, 0, 0, 0.15);
				border-radius: 4px;
				margin: 0 auto;
				font-size: 16px;
				font-family: NotoSansSC, NotoSansSC-Regular;
				font-weight: 400;
				text-align: center;
				color: rgba(0, 0, 0, 0.65);
				cursor: pointer;
				&:hover {
					color: #fff;
					background-color: #00c699;
					border-color: #00c699;
				}
			}
		}
	}
</style>
